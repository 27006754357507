import React, { useState, useEffect } from 'react';
import api from '../services/api.js';

const ProdutoAdicionado = ({ adicionarProduto }) => {
    const [expanded, setExpanded] = useState(false);
    const [isOpenModalConfirmacao, setIsConfirmacaoModal] = useState(false);
    const [IsErrorModalOpen, setIsErrorModalOpen] = useState(false);

    const [errorModalMessage, setErrorModalMessage] = useState('');
    const [produtos, setProdutos] = useState([]);
    const [pedidoId, setPedidoId] = useState(null);
    const [loginId, setLoginId] = useState(null);
    const [arquivoId, setArquivoId] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const getProdutosFromLocalStorage = () => {
        const produtosFromLocalStorage = JSON.parse(localStorage.getItem('produtos')) || [];
        setProdutos(produtosFromLocalStorage);
        const pedidoIdFromLocalStorage = localStorage.getItem('idPedido');
        setPedidoId(pedidoIdFromLocalStorage);
        const loginIdFromLocalStorage = localStorage.getItem('loginId');
        setLoginId(loginIdFromLocalStorage);        
        const arquivoIdFromLocalStorage = localStorage.getItem('idArquivo');
        setArquivoId(arquivoIdFromLocalStorage);        
    };

    useEffect(() => {
        getProdutosFromLocalStorage();

        // Adiciona o listener de evento de teclado
        const handleKeyDown = (event) => {
            if (isOpenModalConfirmacao && event.key === ' ') {
                // Previne a ação padrão da tecla espaço quando o modal está aberto
                event.preventDefault();
            }
            if (isOpenModalConfirmacao && event.key === 'Enter') {             
                event.preventDefault();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        // Limpa o listener de evento ao desmontar o componente
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isOpenModalConfirmacao]);

    useEffect(() => {
        getProdutosFromLocalStorage();        
    }, []);

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    const excluirProduto = (index) => {
        const novosProdutos = produtos.filter((_, i) => i !== index);
        setProdutos(novosProdutos);
        localStorage.setItem('produtos', JSON.stringify(novosProdutos));

        const produtosAtualizados = JSON.parse(localStorage.getItem('produtos'));
        if (!produtosAtualizados || produtosAtualizados.length === 0) {
            localStorage.removeItem('produtos');
            window.location.href = '/';
        }
    };

    const enviarPedido = async () => {
        if (!isSubmitting) {
            setIsSubmitting(true);
            const mesaFromStorage = localStorage.getItem('numeroMesa');
            try {                
                const itens = produtos.map(produto => {
                    let preco = produto.price;
                    if (mesaFromStorage === '399') {
                        preco = preco / 2;
                    }
                    return {
                        produto_id: produto.produtoId,
                        quantidade: produto.quantity,
                        preco: preco,
                        texto_imprimir: produto.name + "|" + produto.observacao,
                        imprimir: true,
                        atrasoMinutos: produto.atrasoMinutos || 0
                    };
                });
                const dadosPedido = {
                    pedido_id: pedidoId,
                    id_arquivo: arquivoId,
                    quem_lancou: loginId, 
                    quem_abriu: loginId, 
                    itens: itens,
                    numero_mesa: mesaFromStorage,
                    n_pessoas_mesa: '1',
                    imprimir: false

                };

                await api.post('mesa-consumo', dadosPedido);

                localStorage.removeItem('produtos');
                localStorage.removeItem('idPedido');
                localStorage.removeItem('numeroMesa');
                localStorage.removeItem('idArquivo');
                localStorage.removeItem('status');
                setIsSubmitting(false);
                openModalConfirmacao();
            } catch (error) {    
                setIsSubmitting(false);
                if (error.response && error.response.data && error.response.data.message) {
                    setErrorModalMessage(error.response.data.message);
                } else {
                    setErrorModalMessage(error.message);
                }
                setIsErrorModalOpen(true);
            }
        }
    };

    const openModalConfirmacao = () => {
        setIsConfirmacaoModal(true);
    };

    const ok = () => {
        window.location.href = '/';
    };


    const handleModalErro = () => {
        setIsErrorModalOpen(false);
    };
    return (
        <div className={`pedido ${expanded ? 'fundo-pedidos' : ''}`}>
            <div className={`content-pedido ${expanded ? 'sub-pedidos' : ''}`}>
            <div onClick={toggleExpand} className={`seta ${expanded ? 'rotate' : ''}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '5px', cursor: 'pointer' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="9" viewBox="0 0 16 9" fill="none">
                    <path d="M7.04478 0.350649L0.358208 7.01299C-0.119404 7.48052 -0.119404 8.18182 0.358208 8.64935C0.83582 9.11688 1.55224 9.11688 2.02985 8.64935L8 2.92208L13.9701 8.64935C14.4478 9.11688 15.1642 9.11688 15.6418 8.64935C15.8806 8.41559 16 8.18182 16 7.83117C16 7.48052 15.8806 7.24675 15.6418 7.01299L8.95522 0.350649C8.35821 -0.116883 7.64179 -0.116883 7.04478 0.350649C7.16418 0.350649 7.16418 0.350649 7.04478 0.350649Z" fill="#4D4A45"/>
                </svg>
            </div>
            <div className='titulo-pedido'>PEDIDO MESA ({localStorage.getItem('numeroMesa')})</div>
            <div className={`produtos-adicionados ${expanded ? 'show-pedidos' : ''}`}>
                <div className='table'>
                    <table className='tabela-produtos'>
                        <thead>
                            <tr>
                                <th>Qtd</th>
                                <th>Produto</th>
                                <th>Obs</th>
                                <th className='text-right'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {produtos.map((produto, index) => (
                                <tr key={index}>
                                    <td>{produto.quantity}</td>
                                    <td dangerouslySetInnerHTML={{__html: produto.name}}></td>
                                    <td dangerouslySetInnerHTML={{__html: produto.observacao}}></td>
                                    <td>
                                        <svg onClick={() => excluirProduto(index)} className='excluir' xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                        <path d="M8.5 0C6.81886 0 5.17547 0.498516 3.77766 1.43251C2.37984 2.3665 1.29037 3.69402 0.647028 5.24719C0.00368291 6.80036 -0.164645 8.50943 0.163329 10.1583C0.491303 11.8071 1.30085 13.3217 2.4896 14.5104C3.67834 15.6992 5.1929 16.5087 6.84174 16.8367C8.49057 17.1646 10.1996 16.9963 11.7528 16.353C13.306 15.7096 14.6335 14.6202 15.5675 13.2223C16.5015 11.8245 17 10.1811 17 8.5C17 7.38376 16.7801 6.27846 16.353 5.24719C15.9258 4.21592 15.2997 3.27889 14.5104 2.48959C13.7211 1.70029 12.7841 1.07419 11.7528 0.647024C10.7215 0.219859 9.61624 0 8.5 0ZM8.5 15.3C7.15509 15.3 5.84038 14.9012 4.72213 14.154C3.60387 13.4068 2.7323 12.3448 2.21762 11.1022C1.70295 9.85971 1.56828 8.49245 1.83066 7.17338C2.09304 5.85431 2.74068 4.64267 3.69168 3.69167C4.64267 2.74068 5.85432 2.09304 7.17339 1.83066C8.49246 1.56828 9.85971 1.70294 11.1022 2.21762C12.3448 2.73229 13.4068 3.60387 14.154 4.72212C14.9012 5.84037 15.3 7.15509 15.3 8.5C15.3 10.3035 14.5836 12.0331 13.3083 13.3083C12.0331 14.5836 10.3035 15.3 8.5 15.3ZM11.9 7.65H5.1C4.87457 7.65 4.65837 7.73955 4.49896 7.89896C4.33956 8.05836 4.25 8.27456 4.25 8.5C4.25 8.72543 4.33956 8.94163 4.49896 9.10104C4.65837 9.26044 4.87457 9.35 5.1 9.35H11.9C12.1254 9.35 12.3416 9.26044 12.501 9.10104C12.6604 8.94163 12.75 8.72543 12.75 8.5C12.75 8.27456 12.6604 8.05836 12.501 7.89896C12.3416 7.73955 12.1254 7.65 11.9 7.65Z" fill="#9C0000"/>
                                        </svg>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className='botao-enviar'>
                    <button onClick={enviarPedido}>Enviar pedido</button>
                </div>
            </div>
            </div>
            {isOpenModalConfirmacao && (
                <div className='content-modal'>
                    <div className='modal-content'>
                        <h3>Pedidos enviados com sucesso</h3>
                        <div className='botoes'>
                            <button className='confirmar' onClick={ok}>Ok</button>
                        </div>
                    </div>
                </div>
            )}
             {IsErrorModalOpen && (
                <div className='content-modal'>
                    <div className='modal-content'>
                        <h3>Erro ao enviar pedido</h3>
                        <p>{errorModalMessage}</p>
                        <div className='botoes'>
                            <button className='confirmar' onClick={handleModalErro}>Ok</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProdutoAdicionado;