import React, { useState } from 'react';
import '../Espera.css';
import { Link } from 'react-router-dom';

const ReceitaMassa = () => {
  const [flourWeight, setFlourWeight] = useState('');
  const [result, setResult] = useState(null);
  const [message, setMessage] = useState('');
  const [selectedRecipe, setSelectedRecipe] = useState('massa'); // Estado para alternar entre receitas


  const clearMessage = () => {
    setTimeout(() => {
      setMessage('');
    }, 5000);
  };

  const calculateIngredients = () => {
    const flour = parseFloat(flourWeight);

    if (!flour || flour <= 0) {
      setMessage('Por favor, insira um valor válido para o peso da farinha.');
      clearMessage();
      return;
    }

    const waterRatio = 3.5 / 10;
    const iceRatio = 4 / 10;
    const yeastRatio = 15 / 10;
    const saltRatio = 260 / 10;
    
    const water = flour * waterRatio;
    const ice = flour * iceRatio;
    const yeast = flour * yeastRatio;
    const salt = flour * saltRatio;

    setResult({
      water: water.toFixed(2),
      ice: ice.toFixed(2),
      yeast: yeast.toFixed(2),
      salt: salt.toFixed(2),
    });
  };

  return (
    <div className="espera-container">
      <span className="titulo-kds">Receitas</span>
      <Link to={`/`} className="close-bar">        
        <span className="titulo-kds">
          Receita {selectedRecipe}
        </span>
        <span className="close-icon">&#10005;</span>
      </Link>
      {/* Botões para alternar entre receitas */}
      <div className="recipe-buttons">
        <button         
          className={`recipe-btn ${selectedRecipe === 'massa' ? 'active' : ''}`} 
          onClick={() => setSelectedRecipe('massa')}
        >
          Massa
        </button>
        <button 
          className={`recipe-btn ${selectedRecipe === 'mascarpone' ? 'active' : ''}`} 
          onClick={() => setSelectedRecipe('mascarpone')}
        >
          Mascarpone
        </button>
      </div>

      {selectedRecipe === 'massa' ? (
        <div className="espera-content">
          {message && (
            <div className={`mensagem ${message.includes('Erro') ? 'erro' : 'sucesso'}`}>
              {message}
            </div>
          )}

          <div className="form-nova-espera">
            <div className="input-row">
              <input
                type="number"
                placeholder="Peso da farinha (kg)"
                className="input-field"
                value={flourWeight}
                onChange={(e) => setFlourWeight(e.target.value)}
              />
            </div>
            <button className="submit-btn" onClick={calculateIngredients}>
              Calcular Ingredientes
            </button>
          </div>

          {result && (
            <div className="ListaEspera" style={{ marginTop: '20px' }}>
              <table className="tabela-esperas">
                <thead>
                  <tr>
                    <th>Ingrediente</th>
                    <th>Quantidade</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Água</td>
                    <td>{result.water} Litros</td>
                  </tr>
                  <tr>
                    <td>Gelo</td>
                    <td>{result.ice} Litros</td>
                  </tr>
                  <tr>
                    <td>Fermento</td>
                    <td>{result.yeast} Gramas</td>
                  </tr>
                  <tr>
                    <td>Sal</td>
                    <td>{result.salt} Gramas</td>
                  </tr>
                </tbody>
              </table>
              <div>
              <p>Farinha e fermento colocar pra misturar 2 minutos.</p>

              <p>adicionar {(result.water + result.ice)* 0.8 } da agua gelada</p>

              <p>Bater na velocidade 1 por aproximadamente 5 minutos. </p>

              <p>Adicionar  {(result.water + result.ice)* 0.2 / 3 } da água que sobrou e bater por mais por mais 5 minutos. </p>

              <p>Mudar pra velocidade 2 e jogar {(result.water + result.ice)* 0.2 / 3 / 2 } Bater por 5 minutos. </p>

              <p>Jogar o sal, o resto da água ({(result.water + result.ice)* 0.2 / 3 / 2 / 2 }), (guardar apenas um pouquinho da água) e aumentar pra velocidade 3 por mais 7 minutos. (Tem que verificar se estruturou bem a massa / abóbora)</p>

              <p>Colocar todo resto da água, e o gelo ir colocando aos poucos (obs: Após 3 minutos começa a formar uma abóbora bem bonita)</p>
              
              <p>Bater por 10 a 12 minutos. Tirar e fazer a dobra.</p>

              <p>Descansar 10 minutos e fazer a dobra novamente.</p>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="mascarpone-recipe">
          <h3>Ingredientes:</h3>
          <p style={{ marginBottom: '-10px' }}>- 1 kg de mascarpone</p>
          <p style={{ marginBottom: '-10px' }}>- 6 ovos (gemas e claras separadas)</p>
          <p style={{ marginBottom: '-10px' }}>- 160g de açúcar</p>
          <p style={{ marginBottom: '-10px' }}>- Café fresco e forte (para umedecer os biscoitos)</p>
          <p style={{ marginBottom: '-10px' }}>- 2 biscoitos por copo</p>
          <h3>Modo de Preparo:</h3>
          <p><strong>Base de Gemas e Mascarpone:</strong></p>
          <p>Em uma tigela grande, coloque as gemas e adicione metade do açúcar (90g). Bata bem até obter um creme espesso e claro. Adicione o mascarpone à mistura de gemas e açúcar, incorporando suavemente até que fique homogêneo e cremoso.</p>
          <p><strong>Preparação das Claras em Neve:</strong></p>
          <p>Em outra tigela, bata as claras em neve. Quando começarem a formar picos, adicione o restante do açúcar (90g) e continue batendo até obter uma textura firme e brilhante.</p>
          <p><strong>Incorporação:</strong></p>
          <p>Com cuidado, adicione as claras em neve à mistura de mascarpone e gemas, misturando delicadamente com uma espátula para manter a leveza e textura.</p>
          <p><strong>Montagem:</strong></p>
          <p>Mergulhe rapidamente os biscoitos no café, sem encharcar demais, para que mantenham a estrutura. Coloque os biscoitos intercalando com o uma camada generosa do creme de mascarpone. Repita as camadas, finalize com creme em cima.</p>
          <p><strong>Finalização:</strong></p>
          <p>Leve à geladeira por pelo menos 4 horas para firmar e intensificar os sabores. Antes de servir, polvilhe cacau em pó por cima.</p>
        </div>
      )}
    </div>
  );
};

export default ReceitaMassa;