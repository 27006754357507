import React, { Component } from 'react'

import Default from './products/Default'

class Product extends Component {
  buildClass = catKey => {
    return 'product product-' + catKey
  }

  render() {
        return <Default
          classes={this.buildClass(this.props.catKey)}
          category={this.props.category}
          name={this.props.name}
          description={this.props.description}
          price={this.props.price}
          prodKey={this.props.prodKey}
          index={this.props.index}
        />
  }
}

export default Product