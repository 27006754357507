import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom'; 
import { getProducts } from '../store/actions/products';
import Header from './Header';
import Menu from './Menu';
import Login from './Login';
import Products from './Products';
import VerResumo from './VerResumo';
import ProdutoAdicionado from './ProdutoAdicionado';
import FechamentoCaixa from './FechamentoCaixa';
import api from '../services/api.js';


class Root extends Component {
  constructor(props) {
    super(props);
    const cachedLogin = localStorage.getItem('isLoggedIn') === 'true';
    this.state = {
      isLoggedIn: cachedLogin,
      exibirNovaTela: false,
    };
  }  
  onLogin = async (username, password) => {
    try {
      const response = await api.post('/login', {
        email: username, 
        password: password,
      });

      const { access_token, codigo, pos } = response.data; 

      if(pos){
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('authToken', access_token); 
        localStorage.setItem('loginId', codigo); 
        localStorage.removeItem('produtos');
        localStorage.removeItem('idPedido');
        localStorage.removeItem('numeroMesa');
        localStorage.removeItem('idArquivo');
        localStorage.removeItem('status');
        this.setState({ isLoggedIn: true });      
        this.props.getProducts(); // Chamada para obter produtos
      }else{        
        this.setState({ isLoggedIn: false });
        const elemento = document.querySelector('.error');
        if (elemento) {
          elemento.style.display = 'block';
        }
      }
      
    } catch (error) {
      console.error('Erro de autenticação:', error);      
      this.setState({ isLoggedIn: false });
      const elemento = document.querySelector('.error');
      if (elemento) {
        elemento.style.display = 'block';
      }
    }
  };
 
  onButtonFecharCaixa = async (username, password) => {
    try {
      const response = await api.post('/login', {
        email: username, 
        password: password,
      });
      const { access_token, codigo } = response.data;       
      // localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem('authToken', access_token); 
      // localStorage.setItem('loginId', codigo); 
      this.setState({ exibirNovaTela: true });
    } catch (error) {
      console.error('Erro de autenticação:', error);      
      this.setState({ isLoggedIn: false });
      const elemento = document.querySelector('.error');
      if (elemento) {
        elemento.style.display = 'block';
      }
    }
  };    

  componentDidMount() {
    // Bloqueia o uso dos botões "voltar" e "avançar" do navegador
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', this.onBackButtonEvent);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.onBackButtonEvent);
  }

  onBackButtonEvent = (e) => {
    e.preventDefault();
    window.history.pushState(null, null, window.location.pathname);
  };
  
  render() {
    
    const { isLoggedIn, exibirNovaTela } = this.state;
    
    if (exibirNovaTela) {
      return(
      <>        
       <FechamentoCaixa />
      </>
      )
    }

    if (!isLoggedIn) {      
      return <Login onLogin={this.onLogin} onButtonFecharCaixa={this.onButtonFecharCaixa} />;
    }
    

    const produtosLocalStorage = JSON.parse(localStorage.getItem('produtos'));
    const temProdutosLocalStorage = produtosLocalStorage && produtosLocalStorage.length > 0;

    const numeroMesa = localStorage.getItem("numeroMesa");
    const temProdutos = !!numeroMesa && temProdutosLocalStorage;
    // const numeroMesaLink = location.state?.numeroMesaLink || '';
    // console.log(numeroMesaLink);
    const { location } = this.props;
    const numeroMesaLink = location.state?.numeroMesaLink || 0;    

    return (
      <>
        <Header numeroMesaLink={numeroMesaLink} />
        {/* <Header /> */}
        <VerResumo />
        <Menu />      
        <Products />
        {temProdutos && <ProdutoAdicionado />}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProducts: () => dispatch(getProducts())
  };
};

export default connect(null, mapDispatchToProps)(withRouter(Root));