import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../services/api.js';
import '../Espera.css';

const Espera = () => {
  const [esperas, setEsperas] = useState([]);
  const [filter, setFilter] = useState('em_espera');
  const [esperaSelecionada, setEsperaSelecionada] = useState(null);
  const [mensagem, setMensagem] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [novaEspera, setNovaEspera] = useState({ nome: '', numero_pessoas: '', comanda: '' });
  const [numeroMesa, setNumeroMesa] = useState('');
  const [reservas, setReservas] = useState([]);

  const limparMensagem = () => {
    setTimeout(() => {
      setMensagem('');
    }, 8000);
  };

  const fetchEsperas = async () => {
    try {
      const response = await api.get('/controle-espera', {
        params: { filter: filter === 'em_espera' ? 'null' : 'not_null' },
      });
      setEsperas(response.data);
    } catch (error) {
      setMensagem('Erro ao buscar a lista de esperas.');
      limparMensagem();
    }
  };

  const fetchReservas = async () => {
    try {
      const response = await api.get('/reservas-do-dia');
      setReservas(response.data);
    } catch (error) {
      setMensagem('Erro ao buscar as reservas do dia.');
      limparMensagem();
    }
  };

  useEffect(() => {
    if (filter === 'em_espera' || filter === 'clicadas') {
      fetchEsperas();
    }
    if (filter === 'reservas') {
      fetchReservas(); // Atualiza as reservas quando a aba "Reservas" é selecionada
    }
  }, [filter]);

  useEffect(() => {
    const intervalId = setInterval(fetchEsperas, 60000);
    return () => clearInterval(intervalId);
  }, [filter]);

  useEffect(() => {
    fetchReservas();
    const intervalId = setInterval(fetchReservas, 180000); // Atualiza reservas a cada 3 minutos
    return () => clearInterval(intervalId);
  }, []);

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedEsperas = [...esperas].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const handleConcluirEspera = async (id, comanda) => {
    if (isNaN(numeroMesa) || numeroMesa < 1 || numeroMesa > 40) {
      setMensagem('Erro: O número da mesa de destino deve estar entre 1 e 40.');
      limparMensagem();
      return;
    }

    try {
      if (numeroMesa) {
        const mesaFromStorage = parseInt(comanda, 10);
        const mesaParaNumero = parseInt(numeroMesa, 10);

        const transferResponse = await api.post('/mesa-pedido/transferir', {
          numero_mesa_de: mesaFromStorage,
          numero_mesa_para: mesaParaNumero,
        });

        if (transferResponse.status !== 200) {
          throw new Error('Erro ao transferir. Verifique se a mesa de destino não está fechada.');
        }
        setMensagem('Transferência de mesa realizada com sucesso.');
      }

      await api.post(`/controle-espera/click/${id}`);
      setEsperas(esperas.filter((espera) => espera.id !== id));
      setEsperaSelecionada(null);
      setMensagem('Espera concluída com sucesso.');
      limparMensagem();
    } catch (error) {
      const mensagemErro = error.response?.data?.message || error.message || 'Erro ao concluir a espera ou transferir mesa.';
      setMensagem(mensagemErro);
      limparMensagem();
    }
  };

  const handleIncluirEspera = async () => {
    const comandaNumber = parseInt(novaEspera.comanda, 10);
    const PessoasNumber = parseInt(novaEspera.numero_pessoas, 10);

    if (isNaN(PessoasNumber) || PessoasNumber < 1 || PessoasNumber > 20) {
      setMensagem('Erro: O número de pessoas para espera deve estar entre 1 e 20.');
      limparMensagem();
      return;
    }

    if (isNaN(comandaNumber) || comandaNumber < 200 || comandaNumber > 299) {
      setMensagem('Erro: A comanda deve estar entre 200 e 299.');
      limparMensagem();
      return;
    }

    try {
      const response = await api.post('/controle-espera', novaEspera);
      setEsperas([...esperas, response.data.data]);
      setNovaEspera({ nome: '', numero_pessoas: '', comanda: '' });
      setMensagem('Espera incluída com sucesso.');
      limparMensagem();
    } catch (error) {
      console.error('Erro ao adicionar nova espera:', error);
      setMensagem('Erro ao adicionar nova espera.');
      limparMensagem();
    }
  };

  const handleRowClick = (espera) => {
    setEsperaSelecionada(esperaSelecionada && esperaSelecionada.id === espera.id ? null : espera);
  };

  const calcularTempoEspera = (createdAt, datahora_click) => {
    const agora = new Date();
    const criado = new Date(createdAt);
    const diff = Math.floor(((datahora_click ? new Date(datahora_click) : agora) - criado) / (1000 * 60));

    const horas = Math.floor(diff / 60);
    const minutos = diff % 60;

    return `${horas}h ${minutos}m`;
  };

  const handleReservaClick = async (id) => {
    setReservas((prevReservas) =>
      prevReservas.map((reserva) =>
        reserva.id === id
          ? { ...reserva, datahora_click: reserva.datahora_click ? null : new Date().toISOString() }
          : reserva
      )
    );

    try {        
      await api.post(`/reserva/click/${id}`);
      fetchReservas();
    } catch (error) {
      setMensagem('Erro ao registrar o clique na reserva.');
      limparMensagem();
    }
  };

  return (
    <div className="espera-container">
      <Link to={`/`} className="close-bar">
        <span className="titulo-kds">Gestão de Espera</span>
        <span className="close-icon">&#10005;</span>
      </Link>

      <div className="espera-content">
        {/* Botões para alternar entre em espera, já clicadas e reservas */}
        <div className="filter-buttons">
          <button className={`filter-btn ${filter === 'em_espera' ? 'active' : ''}`} onClick={() => setFilter('em_espera')}>
            Em Espera
          </button>
          <button className={`filter-btn ${filter === 'clicadas' ? 'active' : ''}`} onClick={() => setFilter('clicadas')}>
            Sentados
          </button>
          <button className={`filter-btn ${filter === 'reservas' ? 'active' : ''}`} onClick={() => setFilter('reservas')}>
            Reservas
          </button>
        </div>

        {mensagem && (
          <div className={`mensagem ${mensagem.includes('Erro') ? 'erro' : 'sucesso'}`}>
            {mensagem}
          </div>
        )}

        {/* Renderização Condicional */}
        {filter !== 'reservas' ? (
          <>
            {/* Formulário para incluir nova espera */}
            <div className="form-nova-espera">
              <div className="input-row">
                <input
                  type="text"
                  placeholder="Nome"
                  className="input-field nome-cliente"
                  value={novaEspera.nome}
                  onChange={(e) => setNovaEspera({ ...novaEspera, nome: e.target.value })}
                />
                <input
                  type="number"
                  placeholder="Pess."
                  className="input-field numero-pessoas"
                  value={novaEspera.numero_pessoas}
                  onChange={(e) => setNovaEspera({ ...novaEspera, numero_pessoas: e.target.value })}
                />
                <input
                  type="number"
                  placeholder="Com."
                  className="input-field numero-comanda"
                  max="999"
                  value={novaEspera.comanda}
                  onChange={(e) => setNovaEspera({ ...novaEspera, comanda: e.target.value })}
                />
              </div>
              <button className="submit-btn" onClick={handleIncluirEspera}>
                Incluir Espera
              </button>
            </div>

            {/* Lista de Esperas */}
            <div className="ListaEspera">
              <table className="tabela-esperas">
                <thead>
                  <tr>
                    <th className="col-nome" onClick={() => handleSort('nome')}>Nome</th>
                    <th className="col-qnt" onClick={() => handleSort('numero_pessoas')}>Qtd</th>
                    <th className="col-comanda" onClick={() => handleSort('comanda')}>Comanda</th>
                    <th className="col-tempo" onClick={() => handleSort('created_at')}>Tempo</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedEsperas.length === 0 ? (
                    <tr>
                      <td colSpan="4">Nenhuma espera encontrada.</td>
                    </tr>
                  ) : (
                    sortedEsperas.map((espera) => (
                      <React.Fragment key={espera.id}>
                        <tr onClick={() => handleRowClick(espera)}>
                          <td>{espera.nome}</td>
                          <td className="col-pessoas">{espera.numero_pessoas}</td>
                          <td className="col-comanda">{espera.comanda}</td>
                          <td>{calcularTempoEspera(espera.created_at, espera.datahora_click)}</td>
                        </tr>
                        {esperaSelecionada && esperaSelecionada.id === espera.id && (
                          <tr>
                            <td colSpan="4">
                              {filter === 'em_espera' && (
                                <div className="transfer-container">
                                  <input
                                    type="number"
                                    placeholder="Mesa Para"
                                    value={numeroMesa}
                                    onChange={(e) => setNumeroMesa(e.target.value)}
                                    className="input-mesa-para"
                                  />
                                  <button
                                    className="concluir-btn"
                                    onClick={() => handleConcluirEspera(espera.id, espera.comanda)}
                                  >
                                    Sentar {espera.nome}
                                  </button>
                                </div>
                              )}
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div className="ListaReservas" style={{ marginTop: '20px' }}>
            <table className="tabela-esperas">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th className="col-qnt">Qtd</th>
                  <th className="col-comanda">Ambiente</th>
                  <th className="col-comanda">Mesa</th>
                  <th className="col-comanda">Hora</th>
                  <th>Observações</th>
                </tr>
              </thead>
              <tbody>
                {reservas.length === 0 ? (
                  <tr>
                    <td colSpan="5">Nenhuma reserva encontrada para hoje.</td>
                  </tr>
                ) : (
                  reservas.map((reserva) => (
                    <tr 
                      key={reserva.id} 
                      onClick={() => handleReservaClick(reserva.id)}
                      style={{ backgroundColor: reserva.datahora_click ? '#FFD700' : 'inherit' }} 
                    >
                      <td>{reserva.nome}</td>
                      <td className="col-pessoas">{reserva.num_pessoas}</td>
                      <td>{reserva.ambiente}</td>
                      <td>{reserva.mesa}</td>
                      <td className="col-pessoas">{reserva.hora}</td>
                      <td>{reserva.observacoes}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Espera;
