const INITIAL_STATE = {
  products: [],
  isFetching: true,
  error: false,
  searchTerm: ''
}

export default function getProducts(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'GET_PRODUCTS_FETCH':
      return {
        products: [],        
        isFetching: true,
        error: false,
        searchTerm: state.searchTerm
      }
    case 'GET_PRODUCTS_SUCCESS':
      return {
        products: action.payload.products.data,        
        isFetching: false,
        error: false,
        searchTerm: state.searchTerm
      }
    case 'GET_PRODUCTS_ERROR':
      return {
        products: [],        
        isFetching: false,
        error: true,
        searchTerm: state.searchTerm
      }    
    case 'SET_SEARCH_TERM':
      return {
        products: state.products,
        prices: state.prices,
        isFetching: state.isFetching,
        error: state.error,
        searchTerm: action.payload.searchTerm
      }
    default: return state
  }
}