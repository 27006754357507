import api from '../../services/api'

export const getProductsFetch = () => {
  return {
    type: 'GET_PRODUCTS_FETCH'
  }
}

export const getProductsSuccess = products => {
  return {
    type: 'GET_PRODUCTS_SUCCESS',
    payload: {
      products: products.data
    }
  }
}

export const getProductsError = () => {
  return {
    type: 'GET_PRODUCTS_ERROR'
  }
}

export const setSearchTerm = term => {
  return {
    type: 'SET_SEARCH_TERM',
    payload: {
      searchTerm: term
    }
  }
}

export const getProducts = () => {  
  return dispatch => {
    dispatch(getProductsFetch())
    api.get('menu')
      .then(response => {
        dispatch(getProductsSuccess(response))        
      })
      .catch(response => dispatch(getProductsError()))
  }
}