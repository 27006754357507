import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import Root from './Root'
import ProductInfo from './ProductInfo'
import KDS from './KDS'  // Certifique-se de importar o componente KDS correto
import MesasAbertas from './MesasAbertas'
import Espera from './Espera'
import PDFViewer from './PDFViewer'
import ReceitaMassa from './ReceitaMassa'

import { getProducts } from '../store/actions/products'

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem('isLoggedIn') === 'true' ? (
        <Component {...props} />
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

class Routes extends Component {
  componentDidMount() {
    if (localStorage.getItem('isLoggedIn') === 'true'){
      this.props.getProducts()
    }    
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route exact={true} path='/'> <Root /> </Route>
          <Route path='/produto'> <ProductInfo /> </Route>          
          <Route path="/mesas-abertas" exact component={MesasAbertas} />
          {/* Ajuste da rota com render para os parâmetros dinâmicos */}
          <Route path="/kds-itens/:tipo/:status" render={(props) => {
            const { tipo, status } = props.match.params;
            const titulo = `${tipo === 'bar' ? 'Bar' : 'Cozinha'} - ${status === 'forno' ? 'Forno' : 'Finalização'}`;
            return <KDS tipo={tipo} status={status} titulo={titulo} />;
          }} />
          <Route path="/espera" exact component={Espera} />
          <Route path="/pdf-viewer" component={PDFViewer} />                
          <ProtectedRoute path="/receita-massa" component={ReceitaMassa} />
        </Switch>
      </Router>
    )
  }
}

const mapStateToProps = state => {
  return {
    isFetching: state.productsReducer.isFetching,
    error: state.productsReducer.error
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getProducts: () => dispatch(getProducts())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes)
