import React, { useState } from 'react';
import logoLogin from '../assets/img/logo-loader.png';

const Login = ({ onLogin, onButtonFecharCaixa }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');


  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  // Manipulador para o campo de senha
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onLogin(email, password);
  };

  const handleButtonFecharCaixa = (event) => {    
    event.preventDefault();    
    onButtonFecharCaixa(email,password);    
  };

  return (
    <div>
      <div className='login'>
        <img src={logoLogin} alt="Logo" />
        <form onSubmit={handleSubmit}>
          <span>Login</span>
          <input
            type="text"
            name='email'            
            value={email}
            onChange={handleEmailChange}
          />
          
          <input
            type="password"
            name='password'            
            value={password}
            onChange={handlePasswordChange}
          />        
          <p className='error'>Código incorreto, ou sem acesso a essa função</p>          
          <button type='submit'>Acessar</button>          
          <div className="versao">Vr1.57</div>
        </form>
      </div>
      
    </div>
  );
}

export default Login;