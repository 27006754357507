import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../services/api.js';

const ListaMesasAbertas = ({ handleNumeroMesaClick }) => {
  const [mesasAbertas, setMesasAbertas] = useState([]);
  const [mesaSelecionada, setMesaSelecionada] = useState(null);

  useEffect(() => {
    async function fetchMesasAbertas() {
      try {
        const response = await api.get('/mesa-pedido/mesas-abertas');
        const mesasArray = Object.values(response.data);
        setMesasAbertas(mesasArray);
      } catch (error) {
        console.error('Erro ao buscar as mesas abertas:', error);
      }
    }

    fetchMesasAbertas();
  }, []);

  const handleRowClick = (mesa) => {
    if (mesaSelecionada && mesaSelecionada.numero_mesa === mesa.numero_mesa) {
      // Se a mesa clicada for a mesma já selecionada, desmarcar (fechar)
      setMesaSelecionada(null);
    } else {
      // Selecionar a mesa clicada (abrir)
      setMesaSelecionada(mesa);
    }
  };

  return (
    <div className="kds-bar">
      <Link to={`/`} className="close-bar">
        <span className="titulo-kds">Soffio - Mesas Abertas</span>
        <span className="close-icon">&#10005;</span>
      </Link>
      <div className='MesasAbertas'>
        <table className="tabela-mesas">
          <thead>
            <tr>
              <th>Mesa</th>
              <th>Abertura</th>
              <th>Quem Abriu</th>
            </tr>
          </thead>
          <tbody>
            {mesasAbertas.map(mesa => (
              <React.Fragment key={mesa.numero_mesa}>
                <tr onClick={() => handleRowClick(mesa)}>                 
                  <td> <Link to={{ pathname: "/", state: { numeroMesaLink: mesa.numero_mesa } }}  
                        style={{ fontWeight: 'bold', textDecoration: 'none', color: 'inherit' }}>
                        {mesa.numero_mesa}
                       </Link>
                  </td>
                  <td>{new Date(mesa.created_at).toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false })}</td>
                  <td>{mesa.quem_abriu.split(' ')[0]}</td>
                </tr>
                {mesaSelecionada && mesaSelecionada.numero_mesa === mesa.numero_mesa && (
                  <tr>
                    <td colSpan="3">
                      <table className="tabela-detalhes-mesa">
                        <thead>
                          <tr>
                            <th>Usuário</th>
                            <th>Qtd</th>
                            <th>Produto</th>
                          </tr>
                        </thead>
                        <tbody>
                        {console.log(mesaSelecionada)}
                          {mesaSelecionada.mesa_consumo.map(item => (
                             <tr
                             key={item.id}
                             style={{
                               backgroundColor: item.entregue === 'N' ? '#f8d7da' :
                                                item.entregue === 'P' ? '#fff3cd' : // Cor diferente para o estado P
                                                '#d4edda',
                               color: item.entregue === 'N' ? '#d80016' :
                                      item.entregue === 'P' ? '#856404' : // Cor diferente para o estado P
                                      '#155724'
                              }}
                              >
                              <td>{item.user.name.split(' ')[0]} {new Date(item.created_at).toLocaleString('pt-BR', { hour: '2-digit', minute: '2-digit', hour12: false })}</td>
                              <td>{item.quantidade}</td>
                              <td>{item.texto_imprimir}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ListaMesasAbertas;
