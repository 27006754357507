import React from 'react';

const PDFViewer = () => {
  return (
    <div className="pdf-container">
      <embed src="drinks.pdf" type="application/pdf" width="100%" height="100%" />            
    </div>
  );
};

export default PDFViewer;
