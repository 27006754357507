import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
// import './fonts/DINProCondensedMedium500.otf'
// import './fonts/DINProMedium500.otf'
// import './fonts/DINProItalic400.otf'
// import './fonts/DINProCondensedItalic400.otf'
// import './fonts/DINProCondensedRegular400.otf'
// import './fonts/DINProCondensedMediumItalic500.otf'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
