import React, { useState, useEffect, useCallback, onMount } from 'react';
import FecharMesa from './FecharMesa';
import api from '../services/api.js';

const VerResumo = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenModalTrocarMesa, setIsOpenModalTrocarMesa] = useState(false);    
    const [isOpenModalFecharMesa, setIsOpenModalFecharMesa] = useState(false);    
    const [isOpenModalProibidoTrocarMesa, setIsOpenModalProibidoTrocarMesa] = useState(false);
    const [isOpenModalMesaJaFechada, setisOpenModalMesaJaFechada] = useState(null);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [selectedProductNome, setSelectedProductNome] = useState(null);    
    const [selectedProductQtd, setSelectedProductQtd] = useState(null);
    const [taxaServico, setTaxaServico] = useState(0.00);
    const [totalValue, setTotalValue] = useState(0.00);
    const [numero_mesa_para, setnumero_mesa_para] = useState('');
    const [n_pessoas_mesa, setn_pessoas_mesa] = useState('');
    const [numeroSenha, setNumeroSenha] = useState('');
    const [motivo, setMotivo] = useState('');
    const mesaFromStorage = localStorage.getItem('numeroMesa');
    const [produtos, setProdutos] = useState([]);    

    const [loginId] = useState(() => {
        const loginIdFromStorage = localStorage.getItem('loginId');        
        return loginIdFromStorage || null;
      });
      

    const [status] = useState(() => {
        const statusFromStorage = localStorage.getItem('status');        
        return statusFromStorage || null;
    });

    //   useEffect(() => {
    //     localStorage.setItem('loginId', loginId);
    //   }, [loginId]);

      

    const fetchConsumoMesa = useCallback(async () => {        
        try {
            const response = await api.get(`mesa-consumo?numero_mesa=${mesaFromStorage}`);    
            const produtosData = response.data || [];
            setProdutos(produtosData);
            calcularTotal(produtosData);
            return produtosData;
        } catch (error) {            
            console.error('Erro ao buscar consumação da mesa:', error);
        }
    }, [mesaFromStorage]);

    const calcularTotal = useCallback((produtosData) => {
        let totalProdutos = 0;         
        produtosData.forEach(produto => {
            if (produto.preco !== undefined) {
                totalProdutos += parseFloat(produto.preco * produto.quantidade);
            }            
        });
        if ((mesaFromStorage > 299 && mesaFromStorage < 400) || (mesaFromStorage >= 100 && mesaFromStorage <= 106)){
            setTaxaServico(0);
        }else{
            setTaxaServico(totalProdutos * 0.13);
        }
        setTotalValue(totalProdutos);
    }, []);

     useEffect(() => {
         setIsOpen(false); 
         if (localStorage.getItem('status') === "Fechada"){                        
            setIsOpen(true);
            toggleAccordion();
            
        }
      }, [status]);

    const openDeleteModal = (productId,productNome,productQtd) => {
        setSelectedProductId(productId);
        setSelectedProductNome(productNome);
        setSelectedProductQtd(productQtd);        
        setIsOpenModal(true);
    };

    const confirmDelete = async () => {
        if (selectedProductId !== null) {
            if (numeroSenha === "5888"){
                if (motivo){                
                    try {
                        await api.delete(`/mesa-consumo/${selectedProductId}/${motivo}`);
                        const updatedProducts = produtos.filter(produto => produto.id !== selectedProductId);
                        setProdutos(updatedProducts); 
                        calcularTotal(updatedProducts); 
                        setIsOpenModal(false);
                    } catch (error) {
                        console.error('Erro ao excluir produto:', error);
                    }
                }else {
                    alert ("Necessário Informar o motivo.")
                }                
            }else {
                alert ("Senha inválida");
            }
        }
    };

    const toggleAccordion = async () => {
        setIsOpen(!isOpen);                        
        if (!isOpen) {
            try {
                const produtosData = await fetchConsumoMesa();                
                if (produtosData) {
                    setProdutos(produtosData);                    
                } else {
                    console.error('Não foi possível obter os dados do consumo da mesa.');
                }
            } catch (error) {
                console.error('Erro ao buscar dados da API:', error);
            }
            if(status === 'Fechada'){
                var elemento2 = document.querySelector('.fecha-mesa');
                elemento2.style.display = 'block';
                var elemento_acao = document.querySelector('.content-botoes');
                elemento_acao.style.display = 'none';                            
            }
        }
    };


    const fecharMesa = async () => {
        try {            
            if (status === 'Aberta'){
                await api.post('/mesa-pedido/fechar', {
                    numero_mesa: mesaFromStorage,
                    quem_fechou: loginId,
                    n_pessoas_mesa: n_pessoas_mesa
                });
                localStorage.removeItem('produtos');
                localStorage.removeItem('idPedido');
                localStorage.removeItem('numeroMesa');
                localStorage.removeItem('idArquivo');
                localStorage.removeItem('status');
                window.location.href = '/';            
            }else{
                var elemento = document.querySelector('.tabela-produtos');
                elemento.style.display = 'none';
                var elemento2 = document.querySelector('.fecha-mesa');
                elemento2.style.display = 'block';
            }
        } catch (error) {
            console.error('Erro ao fechar a mesa:', error);
            alert('Erro ao fechar a mesa. Por favor, tente novamente.');
        }
    };

    const openTrocarMesa = (index) => {
        if (totalValue > 0 ) {
            setIsOpenModalTrocarMesa(true);    
        }else{
            setIsOpenModalProibidoTrocarMesa(true);
        }        
    };
    
    const handleChange = (event) => {
        const newValue = event.target.value;
        if (newValue.length === 0 || (newValue.length <= 3 && parseInt(newValue) >= 1)) {
            setnumero_mesa_para(newValue);
            // Salvando o novo valor no cache
            // localStorage.setItem('numeroMesa', newValue);
        }
    };

    const handleChange2 = (event) => {
        const newValue = event.target.value;
        if (newValue.length === 0 || (newValue.length <= 2 && parseInt(newValue) >= 1)) {
            setn_pessoas_mesa(newValue);
        }
    };
    
    const atualizaMesa = async () => {
        try {                        
            const response = await api.post('/mesa-pedido/transferir', {
                numero_mesa_de: mesaFromStorage,
                numero_mesa_para: numero_mesa_para,
            });
            if (response.status == "201"){
                setisOpenModalMesaJaFechada(true);
            }else{
                localStorage.removeItem('idPedido');
                localStorage.removeItem('idArquivo');
                localStorage.removeItem('status');
                localStorage.removeItem('numeroMesa');
                window.location.href = '/';
            }           
        } catch (error) {            
            alert('Erro ao transferir a consumação da mesa. Por favor, tente novamente.');
        }
    };   

    const handleChangeSenha = (event) => {
        const newValue = event.target.value;
        if (newValue.length === 0 || (newValue.length <= 4 && parseInt(newValue) >= 1)) {
        setNumeroSenha(newValue);
        }
    };

    const handleMotivoChange = (event) => {
        const newValue = event.target.value;
        setMotivo(newValue);
    };
  
    return (   
        <div className='accordion'>
           <div onClick={toggleAccordion} style={{ cursor: 'pointer'}}>
               <span className='abrir' onClick={toggleAccordion}>
            <svg className={isOpen ? 'abrir rotate' : 'abrir'} xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
                <path d="M5.03731 4.80519L8.79851 1.1039C9.06716 0.844156 9.06716 0.454546 8.79851 0.194805C8.52985 -0.0649346 8.12687 -0.0649346 7.85821 0.194805L4.5 3.37662L1.14179 0.194806C0.873134 -0.0649343 0.470149 -0.0649343 0.201492 0.194806C0.0671638 0.324676 -1.98688e-07 0.454546 -1.90173e-07 0.649352C-1.81658e-07 0.844157 0.0671638 0.974027 0.201492 1.1039L3.96269 4.80519C4.29851 5.06493 4.70149 5.06493 5.03731 4.80519C4.97015 4.80519 4.97015 4.80519 5.03731 4.80519Z" fill="#4D4A45"/>
            </svg>Ver Consumo
            {/* {localStorage.getItem('status') == "Fechada" ? 'Receber' : 'Ver consumo'} */}
                </span>
            </div>
            <div className='ver-mesas-abertas'>            
        </div>
            {isOpen && (
            <div className='produtos-adicionados'>
                <table className='tabela-produtos'>
                    <thead>
                        <tr>
                            <th>Qtd</th>
                            <th>Produto</th>
                            <th>Valor unit</th>
                            <th className='text-right'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {produtos.map((produto, index) => (
                        <tr key={index}>
                            <td>{produto.quantidade}</td>
                            <td dangerouslySetInnerHTML={{__html: produto.texto_imprimir}}></td>
                            <td>R$ {produto.preco.toFixed(2)}</td>
                            <td>
                                <svg onClick={() => openDeleteModal(produto.id,produto.texto_imprimir,produto.quantidade)} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                    <path d="M8.5 0C6.81886 0 5.17547 0.498516 3.77766 1.43251C2.37984 2.3665 1.29037 3.69402 0.647028 5.24719C0.00368291 6.80036 -0.164645 8.50943 0.163329 10.1583C0.491303 11.8071 1.30085 13.3217 2.4896 14.5104C3.67834 15.6992 5.1929 16.5087 6.84174 16.8367C8.49057 17.1646 10.1996 16.9963 11.7528 16.353C13.306 15.7096 14.6335 14.6202 15.5675 13.2223C16.5015 11.8245 17 10.1811 17 8.5C17 7.38376 16.7801 6.27846 16.353 5.24719C15.9258 4.21592 15.2997 3.27889 14.5104 2.48959C13.7211 1.70029 12.7841 1.07419 11.7528 0.647024C10.7215 0.219859 9.61624 0 8.5 0ZM8.5 15.3C7.15509 15.3 5.84038 14.9012 4.72213 14.154C3.60387 13.4068 2.7323 12.3448 2.21762 11.1022C1.70295 9.85971 1.56828 8.49245 1.83066 7.17338C2.09304 5.85431 2.74068 4.64267 3.69168 3.69167C4.64267 2.74068 5.85432 2.09304 7.17339 1.83066C8.49246 1.56828 9.85971 1.70294 11.1022 2.21762C12.3448 2.73229 13.4068 3.60387 14.154 4.72212C14.9012 5.84037 15.3 7.15509 15.3 8.5C15.3 10.3035 14.5836 12.0331 13.3083 13.3083C12.0331 14.5836 10.3035 15.3 8.5 15.3ZM11.9 7.65H5.1C4.87457 7.65 4.65837 7.73955 4.49896 7.89896C4.33956 8.05836 4.25 8.27456 4.25 8.5C4.25 8.72543 4.33956 8.94163 4.49896 9.10104C4.65837 9.26044 4.87457 9.35 5.1 9.35H11.9C12.1254 9.35 12.3416 9.26044 12.501 9.10104C12.6604 8.94163 12.75 8.72543 12.75 8.5C12.75 8.27456 12.6604 8.05836 12.501 7.89896C12.3416 7.73955 12.1254 7.65 11.9 7.65Z" fill="#9C0000"/>
                                </svg>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <hr />
                <div className='content-total'>
                    <table>
                        <thead>
                            <tr>
                                <th>Total Produtos</th>
                                <th>Serviço</th>
                                <th className='bold'>Total Conta</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='produtos-quantidade'>R$ {totalValue.toFixed(2)}</td>
                                <td className='taxa-servico'>R$ {taxaServico.toFixed(2)}</td>
                                <td className='bold total-resumo'>R$ {(totalValue + taxaServico).toFixed(2)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <hr />
                <div className='content-botoes'>
                    <button onClick={() => setIsOpenModalFecharMesa(true)}>Fechar mesa</button>
                    <button onClick={openTrocarMesa}>Trocar mesa</button>
                </div>
            </div>
        )}
            {isOpenModal && (
                <div className='content-modal'>
                    <div className='modal-content'>
                    <div className='icon-alert'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="92" height="92" viewBox="0 0 92 92" fill="none">
                        <path d="M46 0C36.9021 0 28.0084 2.69785 20.4438 7.7524C12.8791 12.8069 6.98319 19.9912 3.50156 28.3966C0.0199311 36.802 -0.891022 46.051 0.883898 54.9741C2.65882 63.8973 7.03989 72.0937 13.4731 78.5269C19.9063 84.9601 28.1027 89.3412 37.0259 91.1161C45.949 92.891 55.198 91.9801 63.6034 88.4984C72.0088 85.0168 79.1931 79.1209 84.2476 71.5562C89.3021 63.9916 92 55.0979 92 46C92 39.9592 90.8102 33.9775 88.4985 28.3966C86.1867 22.8156 82.7984 17.7446 78.5269 13.4731C74.2554 9.20159 69.1844 5.81326 63.6034 3.50154C58.0225 1.18983 52.0408 0 46 0ZM46 82.8C38.7217 82.8 31.6068 80.6417 25.555 76.5981C19.5033 72.5544 14.7866 66.8071 12.0013 60.0827C9.21595 53.3584 8.48719 45.9592 9.90712 38.8207C11.3271 31.6822 14.8319 25.125 19.9785 19.9785C25.1251 14.8319 31.6822 11.327 38.8207 9.9071C45.9592 8.48716 53.3584 9.21593 60.0828 12.0012C66.8071 14.7865 72.5544 19.5033 76.5981 25.555C80.6417 31.6067 82.8 38.7216 82.8 46C82.8 55.76 78.9229 65.1202 72.0215 72.0215C65.1202 78.9229 55.76 82.8 46 82.8Z" fill="black"/>
                        <path d="M45.9999 69C48.5404 69 50.5999 66.9405 50.5999 64.4C50.5999 61.8595 48.5404 59.8 45.9999 59.8C43.4594 59.8 41.3999 61.8595 41.3999 64.4C41.3999 66.9405 43.4594 69 45.9999 69Z" fill="black"/>
                        <path d="M45.9999 23C44.7799 23 43.6099 23.4846 42.7472 24.3473C41.8845 25.21 41.3999 26.38 41.3999 27.6V50.6C41.3999 51.82 41.8845 52.99 42.7472 53.8527C43.6099 54.7154 44.7799 55.2 45.9999 55.2C47.2199 55.2 48.3899 54.7154 49.2526 53.8527C50.1153 52.99 50.5999 51.82 50.5999 50.6V27.6C50.5999 26.38 50.1153 25.21 49.2526 24.3473C48.3899 23.4846 47.2199 23 45.9999 23Z" fill="black"/>
                        </svg>
                    </div>
                    <h3>Excluir produto</h3>
                    <p>{selectedProductQtd} X {selectedProductNome}</p>
                    <div className='senha'>
                        <span>Senha</span>
                        <input type="password" value={numeroSenha} onChange={handleChangeSenha}/>
                    </div>
                    <div className='motivo'>
                        <span>Informe o motivo</span>
                        <select name="motivo" id="motivo" value={motivo} onChange={handleMotivoChange}>
                            <option value="">Selecione...</option>
                            <option value="Cliente cancelou">Cliente cancelou</option>
                            <option value="Lançamento errado">Lançamento errado</option>
                            <option value="Transferido">Transferido para outra mesa</option>
                        </select>
                    </div>
                    <div className='botoes'>
                        <button onClick={() => setIsOpenModal(false)}>Cancelar</button>
                        <button className='confirmar' onClick={confirmDelete}>Confirmar</button>
                    </div>
                    </div>
                </div>
            )}
            {isOpenModalTrocarMesa && (
                <div className='content-modal'>
                    <div className='modal-content'>
                        <div className='icon-alert'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="92" height="92" viewBox="0 0 92 92" fill="none">
                            <path d="M46 0C36.9021 0 28.0084 2.69785 20.4438 7.7524C12.8791 12.8069 6.98319 19.9912 3.50156 28.3966C0.0199311 36.802 -0.891022 46.051 0.883898 54.9741C2.65882 63.8973 7.03989 72.0937 13.4731 78.5269C19.9063 84.9601 28.1027 89.3412 37.0259 91.1161C45.949 92.891 55.198 91.9801 63.6034 88.4984C72.0088 85.0168 79.1931 79.1209 84.2476 71.5562C89.3021 63.9916 92 55.0979 92 46C92 39.9592 90.8102 33.9775 88.4985 28.3966C86.1867 22.8156 82.7984 17.7446 78.5269 13.4731C74.2554 9.20159 69.1844 5.81326 63.6034 3.50154C58.0225 1.18983 52.0408 0 46 0ZM46 82.8C38.7217 82.8 31.6068 80.6417 25.555 76.5981C19.5033 72.5544 14.7866 66.8071 12.0013 60.0827C9.21595 53.3584 8.48719 45.9592 9.90712 38.8207C11.3271 31.6822 14.8319 25.125 19.9785 19.9785C25.1251 14.8319 31.6822 11.327 38.8207 9.9071C45.9592 8.48716 53.3584 9.21593 60.0828 12.0012C66.8071 14.7865 72.5544 19.5033 76.5981 25.555C80.6417 31.6067 82.8 38.7216 82.8 46C82.8 55.76 78.9229 65.1202 72.0215 72.0215C65.1202 78.9229 55.76 82.8 46 82.8Z" fill="black"/>
                            <path d="M45.9999 69C48.5404 69 50.5999 66.9405 50.5999 64.4C50.5999 61.8595 48.5404 59.8 45.9999 59.8C43.4594 59.8 41.3999 61.8595 41.3999 64.4C41.3999 66.9405 43.4594 69 45.9999 69Z" fill="black"/>
                            <path d="M45.9999 23C44.7799 23 43.6099 23.4846 42.7472 24.3473C41.8845 25.21 41.3999 26.38 41.3999 27.6V50.6C41.3999 51.82 41.8845 52.99 42.7472 53.8527C43.6099 54.7154 44.7799 55.2 45.9999 55.2C47.2199 55.2 48.3899 54.7154 49.2526 53.8527C50.1153 52.99 50.5999 51.82 50.5999 50.6V27.6C50.5999 26.38 50.1153 25.21 49.2526 24.3473C48.3899 23.4846 47.2199 23 45.9999 23Z" fill="black"/>
                            </svg>
                        </div>
                        <h3>Trocar mesa</h3>
                        <p className='content-p'>De: <strong>{mesaFromStorage}</strong> <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                        <path d="M11.92 5.62409C11.8724 5.50134 11.801 5.3892 11.71 5.29409L6.71 0.294092C6.61676 0.200853 6.50607 0.126893 6.38425 0.0764322C6.26243 0.0259719 6.13186 0 6 0C5.7337 0 5.4783 0.105788 5.29 0.294092C5.19676 0.38733 5.1228 0.498021 5.07234 0.619843C5.02188 0.741665 4.99591 0.872233 4.99591 1.00409C4.99591 1.27039 5.1017 1.52579 5.29 1.71409L8.59 5.00409H1C0.734784 5.00409 0.48043 5.10945 0.292893 5.29699C0.105357 5.48452 0 5.73888 0 6.00409C0 6.26931 0.105357 6.52366 0.292893 6.7112C0.48043 6.89873 0.734784 7.00409 1 7.00409H8.59L5.29 10.2941C5.19627 10.3871 5.12188 10.4977 5.07111 10.6195C5.02034 10.7414 4.9942 10.8721 4.9942 11.0041C4.9942 11.1361 5.02034 11.2668 5.07111 11.3887C5.12188 11.5105 5.19627 11.6211 5.29 11.7141C5.38296 11.8078 5.49356 11.8822 5.61542 11.933C5.73728 11.9838 5.86799 12.0099 6 12.0099C6.13201 12.0099 6.26272 11.9838 6.38458 11.933C6.50644 11.8822 6.61704 11.8078 6.71 11.7141L11.71 6.71409C11.801 6.61899 11.8724 6.50684 11.92 6.38409C12.02 6.14063 12.02 5.86755 11.92 5.62409Z" fill="black"/>
                        </svg>
                        Para: <input type="text" value={numero_mesa_para} onChange={handleChange} />
                        </p>
                        <div className='botoes'>
                            <button onClick={() => setIsOpenModalTrocarMesa(false)}>Cancelar</button>
                            <button className='confirmar' onClick={atualizaMesa}>Confirmar</button>
                        </div>
                    </div>
                </div>
            )}

            {isOpenModalFecharMesa && (
                <div className='content-modal'>
                    <div className='modal-content'>
                        <h3>Informe número pessoas</h3>
                        <p className='content-p'>Número Pessoas: <input type="number" value={n_pessoas_mesa} inputMode="numeric" onChange={handleChange2} /></p>
                        <div className='botoes'>
                            <button onClick={() => setIsOpenModalFecharMesa(false)}>Cancelar</button>
                            <button className='confirmar' onClick={fecharMesa}>Confirmar</button>
                        </div>
                    </div>
                </div>
            )}       
            
            {isOpenModalProibidoTrocarMesa && (
                <div className='content-modal'>
                    <div className='modal-content'>
                        <h3>Mesa Zerada</h3>
                        <p className='content-p'>Mesa atual não tem consumação para ser transferida.</p>
                        <div className='botoes'>
                            <button onClick={() => setIsOpenModalProibidoTrocarMesa(false)}>Ok</button>                            
                        </div>
                    </div>
                </div>
            )}       

            {isOpenModalMesaJaFechada && (
                <div className='content-modal'>
                    <div className='modal-content'>
                        <h3>Impossível transferir</h3>
                        <p className='content-p'>Mesa destino já fechada.</p>
                        <div className='botoes'>
                            <button onClick={() => setisOpenModalMesaJaFechada(false)}>Ok</button>                            
                        </div>
                    </div>
                </div>
            )}       

            <FecharMesa total={(totalValue + taxaServico).toFixed(2)}/>   
        </div>                 
    );
};

export default VerResumo;
